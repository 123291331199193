import React from "react";
import { withPage } from "../../lib/withPage";
import { Layout } from "../../components/Layout";
import { LoyaltyHistory, Wrapper } from "@royalcanin-be-partner-portal/ui-kit";
import { useLoyaltyRetrieveTransactionHistory } from "../../lib/hooks/useLoyaltyRetrieveTransactionHistory";
import { useLoyaltyRetrieveBalance } from "../../lib/hooks/useLoyaltyRetrieveBalance";
import { NetworkStatus } from "@apollo/client";
import { useLoyaltyGetPointsExpirationDate } from "../../lib/hooks/useLoyaltyGetPointsExpirationDate";
import { addMonths, format, isBefore } from "date-fns";

const LoyaltyPage = () => {
  const { data: currentPoints, loading: retrieveBalanceLoading } =
    useLoyaltyRetrieveBalance();
  const {
    data: transactionHistory,
    onLoadMore,
    loading: loadingHistory,
    pageInfo,
    networkStatus,
  } = useLoyaltyRetrieveTransactionHistory({
    sort: "transaction_date DESC",
  });
  const { data: pointsExpirationDate, loading: getPointsExpirationLoading } =
    useLoyaltyGetPointsExpirationDate();

  const loading =
    retrieveBalanceLoading || loadingHistory || getPointsExpirationLoading;
  return (
    <Layout>
      <Wrapper>
        <LoyaltyHistory
          loading={loading}
          points={currentPoints}
          expiringPointsSummary={{
            date:
              pointsExpirationDate?.expiration_date &&
              isBefore(
                new Date(pointsExpirationDate.expiration_date),
                addMonths(new Date(), 3),
              )
                ? format(
                    new Date(pointsExpirationDate.expiration_date),
                    "dd.MM.yyyy",
                  )
                : "",
            points: pointsExpirationDate?.expiration_points
              ? parseInt(pointsExpirationDate?.expiration_points, 10)
              : 0,
          }}
          leftPoints={
            pointsExpirationDate?.data?.map((item) => ({
              date: format(new Date(item.expiration_date), "dd.MM.yyyy"),
              points: parseInt(item.expiration_points, 10),
            })) || []
          }
          historyPoints={transactionHistory.map((item) => ({
            ...item,
            transaction_date: new Date(item.transaction_date),
            total_points: parseInt(item.total_points, 10),
          }))}
          buttonMore={{
            disabled:
              networkStatus === NetworkStatus.fetchMore || loadingHistory,
            onClick: () => onLoadMore(),
            hidden: !pageInfo.hasNextPage,
          }}
          purchaseLink="/loyalty/purchase"
        />
      </Wrapper>
    </Layout>
  );
};

export default withPage(LoyaltyPage);
